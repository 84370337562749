.next-match-overlay {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
  }
  
  .next-match-message {
    color: white;
    font-size: 1.2rem;
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .next-match-button:hover {
    background: white;
    transform: scale(1.05);
  }
  
  /* Add after your existing CSS */
  
  .next-match-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    width: 280px;
    min-height: 80px;
    border-radius: 40px;
    background: linear-gradient(90deg, #3b82f6, #8b5cf6);
  
    color: rgb(0, 0, 0);
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .next-match-button .primary-text {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .next-match-button .subtext {
    font-size: 0.9rem;
    opacity: 0.9;
  }
  
  .next-match-button.winner {
    background: linear-gradient(90deg, #fff, #fff);
    background-size: 200% 100%;
  }
  
  .next-match-button.loser {
    background: linear-gradient(90deg, #fff, #fff);
    background-size: 200% 100%;
  }
  
  .next-match-button:hover {
    transform: scale(1.02);
  }
  
  .next-match-button:active {
    transform: scale(0.98);
  }
  
  .streak-counter {
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.9);
    margin-top: 8px;
  }
  
  .doubled-image {
    width: auto;
    height: 60px; /* Adjust this value to match the size of your previous text */
    animation: flash 0.5s ease-in-out infinite;
  }
  
  .dust-image {
    width: auto;
    height: 60px; /* Adjust this value to match the size of your previous text */
    animation: flash 0.5s ease-in-out infinite;
  }
  
  @keyframes buttonPulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.02);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes gradientShift {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
  
  .next-match-button {
    animation: buttonPulse 2s infinite ease-in-out,
      gradientShift 3s infinite linear;
  }
  
  .next-match-overlay {
    animation: fadeIn 0.4s ease-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate(-50%, -40%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
  