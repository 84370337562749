/* Define background color and general styles */
.app {
  background-color: rgb(27, 29, 40);
  color: white;
  font-family: Arial, sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.start-game {
  cursor: pointer;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 30px; /* Adjust padding to match reference */
  background-color: rgb(27, 29, 40); /* Match your background color */
}

.nav-left {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 30px; /* Add left padding */
}

.nav-logo {
  height: 45px; /* Adjust size as needed */
  width: auto;
}

.nav-links {
  display: flex;
  gap: 1rem;
}

.nav-link,
.nav-button {
  color: white;
  text-decoration: none;
  font-size: 16px;
  padding: 2px 8px;
  transition: opacity 0.2s;
  background: none;
  border: none;
  cursor: pointer;
}

.nav-link:hover,
.nav-button:hover {
  opacity: 0.8;
}

.card-container {
  margin-top: 20px; /* Add space below navbar */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.start-new-coin {
  font-size: 24px;
  color: white;
  cursor: pointer;
}

.start-new-image {
  width: 650px; /* Increase from previous size */
  height: auto;
}

.main-card {
  margin-bottom: 10px;
  margin-top: none;
}

.card-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Create 3 equal columns */
  gap: 20px; /* Space between cards */
  width: 100%;
  max-width: 1200px; /* Adjust this to fit your design */
  margin: 0 auto; /* Center the card list */
}

/* Existing styles... */

.card {
  padding: 20px;
  display: flex;
  align-items: center;
  height: 100px; /* Increased height for vertical size */
  cursor: pointer; /* Make cursor a pointer to indicate it's clickable */
  transition: border 0.3s; /* Smooth transition for the hover effect */
}

.card:hover {
  border: 2px solid white; /* White border on hover */
}

.card-content {
  display: flex;
  align-items: center;
  height: 100%; /* Ensure content fills card height */
}

.card-image {
  width: 80px;
  height: 80px;
  margin-right: 15px; /* Increased margin for better spacing */
}

.card-text {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center text vertically */
  max-width: 250px; /* Set a max width for the text container */
  word-wrap: break-word; /* Allows long text to wrap onto the next line */
}

.card-text h2,
.card-text p {
  font-size: 14px;
  margin: 0;
  overflow-wrap: break-word; /* Helps with breaking long words like addresses */
}

/* General Styles for Token Details Page */
.token-detail-container {
  background-color: rgb(27, 29, 40);
  color: white;
  font-family: Arial, sans-serif;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.token-details-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-top: 20px;
  gap: 40px; /* Add space between columns */
}

.token-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid rgb(134, 239, 172);
  border-radius: 8px;
  background-color: rgb(30, 32, 45);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.token-details h2 {
  font-size: 24px;
  color: white;
  margin-bottom: 20px;
}

.token-detail-image {
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.token-details p {
  font-size: 14px;
  margin-bottom: 10px;
  word-wrap: break-word;
}

.right-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid rgb(134, 239, 172);
  border-radius: 8px;
  background-color: rgb(30, 32, 45);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.progress-bars {
  width: 100%;
  margin-bottom: 30px;
}

.progress-container {
  margin-bottom: 20px;
}

.progress-bar {
  width: 100%;
  background-color: rgb(50, 52, 65);
  border-radius: 4px;
  height: 20px;
  margin-top: 10px;
}

.progress {
  height: 100%;
  background-color: rgb(134, 239, 172);
  border-radius: 4px;
}

.buy-tokens {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.buy-input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid rgb(134, 239, 172);
  border-radius: 4px;
  background-color: white;
  color: black;
  font-size: 13px;
  width: 250px;
}

.buy-button {
  background-color: rgb(134, 239, 172);
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  color: black;
  font-size: 13px;
  cursor: pointer;
}

/* Modal Styles */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(27, 29, 40, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: rgb(27, 29, 40);
  padding: 30px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  position: relative;
  border: 1px solid rgb(134, 239, 172);
}

.modal-content h4 {
  margin-bottom: 20px;
  font-size: 18px;
  color: white;
}

.confirm-button,
.cancel-button {
  background-color: rgb(134, 239, 172);
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  color: black;
  font-size: 13px;
  cursor: pointer;
  margin: 5px;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

/* Data Table Styles */
.data-table {
  width: 80%; /* Make table narrower */
  max-width: 800px; /* Set a max-width for table */
  margin-top: 20px;
  border-collapse: collapse;
  border: 1px solid rgb(134, 239, 172);
  border-radius: 8px;
  overflow: hidden;
  background-color: rgb(30, 32, 45);
  font-size: 15px;
}

.data-table th,
.data-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid rgb(134, 239, 172);
  color: white;
}

.data-table th {
  background-color: rgb(50, 52, 65);
}

.data-table tr:hover {
  background-color: rgb(50, 52, 65);
}

/* Go Back Button Styles */
.go-back-button {
  background-color: rgb(134, 239, 172);
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  color: black;
  font-size: 13px;
  cursor: pointer;
  margin-bottom: 20px;
  text-align: center;
}

/* Styles for TokenCreate page */
.token-create-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: rgb(27, 29, 40); /* Same background color as app */
  border-radius: 8px;
}

.token-create-container h2 {
  color: white;
  font-size: 24px;
  margin-bottom: 20px;
}

.info-text {
  color: rgb(134, 239, 172);
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}

.input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input-field {
  padding: 10px;
  border: 1px solid rgb(134, 239, 172);
  border-radius: 4px;
  background-color: rgb(27, 29, 40);
  color: white;
  font-size: 14px;
  margin-bottom: 10px;
  width: 100%;
}

textarea.input-field {
  resize: vertical;
  min-height: 100px;
}

.create-button {
  background-color: rgb(134, 239, 172);
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  color: black;
  font-size: 14px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .navbar {
    flex-direction: column;
    padding: 10px;
  }
  .nav-left {
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  .nav-links {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }
  .card-list {
    grid-template-columns: 1fr;
  }
}

.game-display-container {
  width: 100%;
  max-width: 800px;
  margin: 20px auto 0;
  padding: 20px;
  position: relative; /* Add this */
}

.game-section {
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  background-color: rgb(27, 29, 40);
}

.game-section.active {
  display: block;
}

.game-display {
  width: 100%;
  position: relative;
}

.match-headline {
  color: rgb(134, 239, 172);
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.match-video {
  width: 100%;
  border-radius: 8px;
}

.countdown-display {
  text-align: center;
  color: white;
  padding: 20px;
}

.countdown-display h2 {
  font-size: 36px;
  color: rgb(134, 239, 172);
}

.outcome-overlay {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  font-weight: bold;
  animation: flash 0.5s ease-in-out infinite;
}

.outcome-overlay.win {
  color: rgb(134, 239, 172);
}

.outcome-overlay.lose {
  color: #ff4444;
}

@keyframes flash {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.outcome-options {
  display: flex;
  align-items: start; /* Changed from center */
  justify-content: center;
  gap: 15px;
  margin: 15px 0 25px;
  font-size: 18px;
}

.option-container {
  display: grid;
  grid-template-rows: 1fr auto; /* Two rows: option and indicator */
  justify-items: center;
  height: 100%;
}

.option {
  padding: 8px 15px;
  border-radius: 4px;
  background: rgb(30, 32, 45);
  /* Ensure both options have same height */
  margin-bottom: 5px; /* Space for YOU indicator */
}

.vs {
  color: rgb(134, 239, 172);
  font-weight: bold;
  /* Align VS with options */
  margin-top: 8px; /* Match option padding */
}

.your-choice {
  background: rgb(50, 52, 65);
  border: 2px solid rgb(134, 239, 172);
}

.you-indicator {
  color: rgb(134, 239, 172);
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
}

.wallet-adapter-button {
  background: none !important;
  border: none !important;
  color: white !important;
  padding: 2px 8px !important;
  font-size: 16px !important;
  font-family: Arial, sans-serif !important;
  text-transform: none !important;
}

.wallet-adapter-button::before {
  content: "[";
}

.wallet-adapter-button::after {
  content: "]";
}

.wallet-adapter-button:not(.wallet-adapter-button-trigger) {
  background: none !important;
}

.wallet-adapter-button:hover {
  opacity: 0.8;
}

.wallet-adapter-modal {
  background: rgb(27, 29, 40) !important;
}

.wallet-adapter-modal-wrapper {
  background: rgb(27, 29, 40) !important;
}

.wallet-adapter-modal-title {
  font-size: 24px !important;
  text-transform: lowercase !important;
  font-family: Arial, sans-serif !important;
}

.wallet-adapter-button-list {
  padding: 20px !important;
}

.wallet-adapter-button-list .wallet-adapter-button {
  background: rgb(30, 32, 45) !important;
  border: 1px solid rgb(134, 239, 172) !important;
  border-radius: 8px !important;
  padding: 15px 30px !important;
  font-size: 18px !important;
  width: 100% !important;
  margin: 10px 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px !important;
}

.wallet-adapter-button-list .wallet-adapter-button:hover {
  background: rgb(40, 42, 55) !important;
}

.wallet-adapter-modal-list-more {
  color: white !important;
}

.wallet-adapter-modal-button-close {
  background: none !important;
}

.random-quote {
  font-style: italic;
  margin: 15px 0;
  color: #ecebeb;
  font-size: 16px;
}

.player-role {
  font-weight: bold;
  margin-top: 10px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 1000;
}

.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid white;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  color: white;
}

.error-message button {
  margin-top: 10px;
  padding: 8px 16px;
  background: none;
  border: 1px solid white;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

/* Wallet Actions Modal specific styles */
.wallet-actions-modal .modal-content {
  background-color: rgb(27, 29, 40);
  border: 1px solid rgb(134, 239, 172);
  max-width: 400px;
}

.wallet-actions-modal input[type="number"] {
  background-color: rgb(30, 32, 45);
  border: 1px solid rgb(134, 239, 172);
  color: white;
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border-radius: 4px;
}

.wallet-actions-modal input[type="number"]:focus {
  outline: none;
  border-color: white;
}

.wallet-actions-modal button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.wallet-actions-modal .error-message {
  color: #ff4444;
  font-size: 14px;
  margin: 10px 0;
  position: static;
  transform: none;
  background: none;
  padding: 0;
}

.wallet-actions-modal .action-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.wallet-actions-modal .action-button {
  flex: 1;
  padding: 10px;
  background: none;
  border: 1px solid rgb(134, 239, 172);
  color: white;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.wallet-actions-modal .action-button.active {
  background-color: rgb(134, 239, 172);
  color: rgb(27, 29, 40);
}

.wallet-actions-modal .submit-button {
  width: 100%;
  padding: 12px;
  background: none;
  border: 1px solid rgb(134, 239, 172);
  color: white;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
  transition: all 0.2s;
}

.wallet-actions-modal .submit-button:hover:not(:disabled) {
  background-color: rgb(134, 239, 172);
  color: rgb(27, 29, 40);
}

.balance-display {
  position: absolute; /* Changed from fixed */
  top: 28%; /* Adjust this value to fine-tune vertical position */
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgb(134, 239, 172);
  padding: 1px 20px;
  border-radius: 4px;
  z-index: 100;
  transition: all 0.3s ease;
  margin-top: -20px; /* Adjust this value to move up/down relative to center */
}

.balance-display p {
  margin: 0;
  padding: 0;
}

.balance-update {
  animation: balanceFlash 1s ease;
}

@keyframes balanceFlash {
  0%,
  100% {
    transform: translate(-50%, -50%) scale(1);
    background: rgb(30, 32, 45);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.1);
    background: rgb(40, 42, 55);
  }
}

.wallet-display {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 1rem 0;
}

.wallet-pair {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wallet-address {
  font-family: monospace;
  font-size: 1.4rem; /* Slightly smaller than the h2 */
  font-weight: 500; /* Medium weight for readability */
}

.wallet-label {
  font-size: 0.9rem; /* Smaller than wallet address */
  opacity: 0.8;
  margin-top: 0.25rem;
}

.vs {
  font-size: 1.2rem; /* Slightly smaller than wallet address */
  margin: 0 0.5rem;
}

@media screen and (max-width: 768px) {
  /* Core navbar layout */
  .navbar {
    flex-direction: row !important;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px 15px;
    width: 100%;
    height: auto;

    top: 0;
    background-color: rgb(27, 29, 40);
    z-index: 1000;
    gap: 0;
    border-bottom: none; /* Remove any border if present */
    box-shadow: none; /* Remove any shadow if present */
    padding-bottom: 0;
  }

  /* Left section with logo and links */
  .nav-left {
    flex-direction: row !important;
    align-items: flex-start;
    width: auto;
    padding: 5px 10px;
    gap: 15px;
  }

  /* Navigation links container */
  .nav-links {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    gap: 2px;
    align-items: start;
    margin: 0;
  }

  .nav-link {
    font-size: 15px;
    white-space: nowrap;
    padding: 0 4px;
    margin: 0;
  }

  /* Specific positioning for support link */
  .nav-link:nth-child(3) {
    grid-column: 1;
    grid-row: 2;
    margin-top: -2px;
  }

  /* Keep about link in its position */
  .nav-link:nth-child(2) {
    grid-column: 2;
    grid-row: 1;
  }

  /* Logo sizing */
  .nav-logo {
    height: 30px;
    margin: 0;
  }

  .nav-button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 14%; /* Position it above the balance display */
    font-size: 13px;
    padding: 4px 8px;
    margin: 0;
    white-space: nowrap;
    z-index: 101; /* Ensure it's above other elements but below the nav */
  }

  /* Container for wallet buttons */
  .navbar > div:last-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-left: auto;
  }

  .wallet-adapter-button {
    font-size: 13px;
    padding: 0 4px;
    margin: 0;
  }

  .wallet-adapter-button-start-icon {
    display: none !important;
  }

  /* Content container */
  .card-container {
    margin-top: 20px; /* Add space below navbar */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* Main logo and balance */
  .start-new-image {
    width: 90vw;
    max-width: 400px;
    height: auto;
    margin-top: 30px;
  }

  .start-game {
    margin-top: 0px; /* Adjust space between logo and start text */
    font-size: 18px; /* Adjust font size for mobile */
    padding: 0px; /* Add some padding around the text */
    text-align: center;
  }

  .wallet-adapter-dropdown {
    position: relative;
    z-index: 1000;
  }

  .wallet-adapter-dropdown-list {
    z-index: 1000;
    position: absolute;
    top: 100%;
    right: 0;
    width: auto;
  }

  /* Add overlay to handle clicks outside */
  .wallet-adapter-dropdown.wallet-adapter-dropdown-open::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    z-index: 999;
  }

  /* Adjust balance display z-index to be lower */
  .balance-display {
    z-index: 100;
  }

  .balance-display {
    top: 16%;
    width: auto;
    padding: 1px 15px;
    font-size: 14px;
  }

  /* Game display */
  .game-display {
    padding: 10px;
  }

  .match-headline {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .outcome-options {
    gap: 10px;
    font-size: 16px;
  }

  .option {
    padding: 6px 12px;
  }

  /* Arena Modal Adjustments */
  .modal-content {
    max-height: 80vh; /* Reduce from 90vh to give more space */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
    max-width: 90%;
    padding: 15px;
    border-radius: 10px;
  }

  .modal-content div[style*="display: flex"] {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  /* Arena Selection Images */
  .modal-content button img {
    width: 100px;
    height: 100px;
  }

  /* Arena Text */
  .modal-content p {
    font-size: 14px;
    margin: 5px 0;
  }

  .modal-content h2 {
    font-size: 20px;
  }

  /* Countdown display */
  .countdown-display {
    padding: 15px;
  }

  .countdown-display h2 {
    font-size: 28px;
  }

  .random-quote {
    font-size: 14px;
    margin: 10px 0;
    padding: 0 15px;
  }

  .wallet-adapter-dropdown-list {
    z-index: 1000;
    position: fixed; /* Change from absolute to fixed */
    top: auto; /* Reset top positioning */
    right: 8px; /* Keep aligned to the right */
    width: auto; /* Let it size to content */
  }

  /* Add overlay to handle clicks outside */
  .wallet-adapter-dropdown.wallet-adapter-dropdown-open::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    z-index: 999;
  }
}
