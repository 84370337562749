.box-choice {
    position: relative;
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .boxes-container {
    position: relative;
    display: flex;
    flex-direction: column; /* Changed from row to column */
    gap: 40px;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
  
  .choice-box {
    width: 60px;
    height: 60px;
    border: 3px solid #fff;
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    position: relative;
    padding: 20px;
    overflow: hidden;
  }
  
  .choice-box:hover {
    transform: scale(1.05);
    background: rgba(255, 255, 255, 0.2);
  }
  
  .choice-box.selected {
    border-color: #ffd700;
    background: rgba(255, 215, 0, 0.2);
    cursor: default;
    transform: scale(1.05);
  }
  
  .choice-box.revealing {
    transition: all 0.5s ease;
  }
  
  .choice-box.complete {
    transition: all 0.5s ease;
  }
  
  .choice-box.up,
  .choice-box.down {
    width: 60px;
    height: 60px;
  }
  
  .box-label {
    font-size: 24px;
    color: #fff;
    font-weight: bold;
  }
  
  .choice-prompt {
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 18px;
    white-space: nowrap;
  }
  .box-logo {
    width: 40px; /* Adjust size as needed */
    height: 40px; /* Adjust size as needed */
    object-fit: contain;
    opacity: 0.8;
    transition: opacity 0.3s ease;
  }
  
  .choice-box:hover .box-logo {
    opacity: 1;
  }
  
  .outcome-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    text-align: center;
    width: 100%;
    opacity: 0;
    animation: fadeIn 0.3s ease forwards;
  }
  
  .choice-section {
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
  
  .countdown-timer {
    font-size: 32px;
    font-weight: bold;
    margin-top: 15px;
    color: #fff;
  }
  
  /* Add pulse animation for final seconds */
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .countdown-timer.final {
    color: #ff0000;
    animation: pulse 0.5s infinite;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  