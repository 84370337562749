.arena-selection-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .arena-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #86efac; /* Changed to theme green */
  }
  
  .arena-subtitle {
    font-size: 16px;
    font-weight: 400;
    color: white;
    margin-bottom: 30px; /* Space before arena buttons */
  }
  
  .arenas-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 35px; /* Increased consistent spacing */
    margin: 0 auto;
    width: fit-content; /* Changed from 100% to fit-content */
  }
  
  .arena-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 80px; /* Fixed width to match button */
  }
  
  .arena-name {
    color: white;
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 400;
    text-transform: lowercase;
  }
  
  .arena-button {
    background: transparent;
    border: 1px solid white;
    border-radius: 4px;
    cursor: pointer;
    padding: 15px;
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition: border-color 0.2s ease;
  }
  
  .arena-button.selected {
    border-color: #86efac;
  }
  
  .arena-button:hover {
    border-color: #86efac;
  }
  
  .arena-button-title {
    color: white;
    font-size: 12px;
    margin-bottom: 5px;
  }
  
  .arena-button-amount {
    color: white;
    font-size: 12px;
    margin-top: 5px;
  }
  
  .arena-image {
    width: 80%; /* Increased from 60% */
    height: auto;
    object-fit: contain;
    margin: auto; /* Centers image vertically */
  }
  
  .arena-amount {
    color: white;
    margin-top: 4px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  
  .confirm-button {
    background: #86efac; /* Added green background */
    border: none;
    border-radius: 4px;
    color: black; /* Changed to black for better contrast */
    padding: 12px 30px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
    transition: opacity 0.2s ease;
  }
  
  .confirm-button:hover {
    opacity: 0.9;
  }
  